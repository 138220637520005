
// Fonts
@import url("https://fonts.googleapis.com/css?family=Raleway:300,400,600");

// Variables
@import "variables";

// Bootstrap
@import "~bootstrap-sass/assets/stylesheets/bootstrap";

/*
 Theme Name:   Spacer
 Theme URI:    http://example.com/spacers
 Description:  Spacers - Storefront Child Theme
 Author:       Frank Castelijns
 Author URI:   http://example.com
 Template:     storefront
 Version:      1.0.0
 License:      GNU General Public License v2 or later
 License URI:  http://www.gnu.org/licenses/gpl-2.0.html
 Tags:         Custom,repsonsive
 Text Domain:  spacers
*/

#vue{
  min-height:600px;
}
.visual-selector-box{
  float:left;
  width:99px;
  height:49px;
  text-align:center;
  font-weight:bold;
  line-height:49px;
  margin:4px;
  border-radius:3px;
  border:1px solid #9aa0a7;
  background:white;
  cursor:pointer;
}
.visual-selector-box.make{
  width:30%;
  min-width:250px;
}
.visual-selector-box.mode{
  width:34%;
  margin:8%;
  height:250px;
  min-width:250px;
}
.visual-selector-box.model-widen{

}
.visual-selector-box:hover{
  background:#ce0108;
  transform:scale(1.05);
  color:white;
}

.list-complete-item {
  transition: all 0.7s;
  display: inline-block;
}
.list-complete-enter, .list-complete-leave-to
  /* .list-complete-leave-active for <2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}
.product.list-complete-enter, .product-list-complete-leave-to{
  transform:translateY(300px);
}
.list-complete-leave-active {
  position:absolute;
}
.filter-wrap{
  width:100%;
  padding:30px;
}
.filter-wrap input{
  width:80%;
  margin:25px 10%;
}
#primary{
  width:1150px;
  max-width:100%;
  margin:0 auto;
}
#stage-controller{
  display:flex;
  flex-direction:row;
  background:rgba(154, 160, 167,0.7);
  margin-bottom: 20px;
}
.container{
  max-width:100%;
}
.col-full{
  max-width: 86.4989378333em;
}
.btn-side{
  margin: 0 auto;
  display: block;
  height: 100px;
  width: 100px;
  border-radius: 50%;
}
.car-stage{
  padding:25px;
  transition:0.7s all;
  background:transparent;
  font-weight:bold;
  color:white;
  position:relative;
}
.car-stage.current{
  background:#ce0108;
  padding:25px 80px;
  margin:-2px;
  z-index: 3;
  position: relative;
}
.car-stage.current:after{
  content: "";
  position: absolute;
  left: calc(50% - 10px);
  bottom:0;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  border-bottom: 10px solid white;
}
.car-stage.passed:after{
  content: "";
  position: absolute;
  z-index: 2;
  right: -4px;
  top: 34px;
  border-top: 4px solid transparent;
  border-left: 4px solid white;
  border-bottom: 4px solid transparent;
  border-right: 0;
}
.car-stage.passed:last-of-type:after{
  border:0 !important;
}
.car-stage.passed{
  cursor:pointer;
}
#car-outline{
  width: 100%;
  margin-top: -180px;
  z-index: -1;
  position: relative;
  margin-bottom: -80px;
}
.col-md-6{
  width:50%;
  //min-height:600px;
  float:left;
  padding:0px 30px 30px;
}
.form-control{
  width:100%;
}
span.clarify{
  font-size: 0.9em;
  color: #999;
  padding-left: 15px;
  display: block;
}
.fa-cog{
  transition: 0.5s all cubic-bezier(0.39, -0.51, 0.99, 1.72);
  font-size:24px;
  margin:15px;
  float:right;
  cursor:pointer;
}
.fa-cog.modding{
  transform:rotateZ(95deg);
}
div.div{
  padding:15px;
  opacity:0;
  margin-right:80px;
  transition: 0.5s all cubic-bezier(0.39, -0.51, 0.99, 1.72);
}

div.div.modding{
  opacity:1;
}
.needy{
  border:1px solid red;
}
.needy:after{
  content:"A recent change has invalided your choice here, please pick again";
  display:block;
  color:red;
}
.empty{
  border:1px solid orange;
}
.empty:after{
  content:"A recent change might have invalided your choice here. Please fill out red fields first";
  display:block;
  color:orange;
}
#modecontainer{
}
.texthelper{
  min-height:600px
}

span.name{
  white-space: nowrap;
}
.no-mobile{
  display:block;
}
.mobile-only{
  display:none;
}
@media (max-width:800px){
  .no-mobile{
    display:none;
  }
  .mobile-only{
    display:block;
  }
  .custom-logo-link img.custom-logo {
    width: 100% !important;
  }
  .site-header, .secondary-navigation ul ul, .main-navigation ul.menu > li.menu-item-has-children:after, .secondary-navigation ul.menu ul, .storefront-handheld-footer-bar, .storefront-handheld-footer-bar ul li > a, .storefront-handheld-footer-bar ul li.search .site-search, button.menu-toggle, button.menu-toggle:hover {
    background:#b50000;
  }
  #content{
    margin-top:-80px;

    .col-full{
      margin:0;
      .row{
        margin:0;
      }
      .center-row .big-option{
        width:100%;
      }
    }
    #main{
      >div:first-child{
        padding:5px 15px;
      }
    }
  }
  .car-stage {
    padding:15px 7px;
    &.current{
      padding:15px 30px;
    }
    &.passed{
      >div{
        display:none;
      }
      &:after{
        top:23px;
      }
      &:before{
        content:'o'
      }
    }
  }
  .visual-selector-box{
    margin:0;
    width:33.3%;
  }
  #changecontainer{
    .col-md-10{
      display: flex;
      flex-direction: column;
      h2{
        margin-top: 110px;
      }
      .col-md-6{
        width: 100%;
        padding: 0;
        &.texthelper{
          position: absolute;
          top: 0;
          height: 120px;
          overflow: hidden;
          left: 0px;
          min-height: 0;
          width: 100vw;
          margin: -15px;
          border-radius: 0;
          display: flex;
          flex-direction: row;
          &.stuck{
            position:fixed;
            margin:0;
            z-index:99;
          }
          .clarify{
            font-size:10px;
          }
          svg{
            width:100px;
          }
        }
      }
    }
  }
  .result-row{
    flex-direction:column;
    .col-md{
      max-width:100%;
    }
  }
  .vue-product{
    box-shadow:none;
    border-radius:0;
    padding:0;
    background:inherit;
    table td{
      display:block;
    }
    table tr{
      display:block;
    }
    table th{
      display:none;
    }
  }
  .carbrandtablewrap{
    width:auto;
    display:flex;
    border:none;
    height:150px;
    margin:0;
    button:focus,button.picked{
      outline:2px solid black;
    }
  }
  .nextbutton{
    width:66%;
    margin:0 auto;
    height:55px;
    display:block;
    position:relative;
    margin-top:20px;
  }
}
@media (min-width: 1400px){
  .container {
    width: 1370px;
  }
}